import MuiMenuItem from '@material-ui/core/MenuItem'
import { fonts } from '@/utils/preset'

export default MuiMenuItem

const getColor = (props) => {
  if (props.type === 'contrast') {
    return props.theme.text.contrast
  } else {
    return props.theme.text.default
  }
}

export const make = props => {
  const color = getColor(props)
  return {
    MenuItem_root: {
      color: color,
      fontSize: 14,
      fontFamily: fonts.base
    }
  }
}

export const get = classes => {
  const filteredClasses = {}
  Object.keys(classes).forEach(className => {
    if (className.indexOf('MenuItem_') === 0) {
      filteredClasses[className.replace('MenuItem_', '')] = classes[className]
    }
  })
  return filteredClasses
}
