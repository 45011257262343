import React from 'react'
import MuiButton from '@material-ui/core/Button'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { fonts } from '@/utils/preset'

const TextButton = props => {
  const { theme, type, classes, children, className, active, ...other } = props
  return (
    <MuiButton
      className={className}
      classes={classes}
      {...other}
    >
      { children }
    </MuiButton>
  )
}

const getStyles = (props) => {
  if (props.type === 'contrast') {
    return {
      color: props.theme.text.contrast
    }
  } else {
    return {
      color: props.theme.text.default
    }
  }
}

const muiStyles = props => {
  const styles = getStyles(props)
  return {
    root: {
      position: 'relative',
      color: styles.color,
      height: 40,
      padding: '0 16px',
      fontSize: 14,
      fontFamily: fonts.base,
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: fade(styles.color, 0.05),
        '@media (hover: none)': {
          backgroundColor: 'transparent'
        },
        '&:disabled': {
          backgroundColor: 'transparent'
        }
      },
      '&:disabled': {
        color: styles.color,
        opacity: 0.6
      }
    }
  }
}

export default ThemeConsumer(WithStylesProps(muiStyles)(TextButton))
