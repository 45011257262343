import { createAction, handleActions } from 'redux-actions'

const ACTION_TYPE = {
  SET_HEAD_RECT: 'app/UI/Layout/SET_HEAD_RECT',
  SET_FOOT_RECT: 'app/UI/Layout/SET_FOOT_RECT'
}

const initialState = {
  headRect: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    x: 0,
    y: 0,
    width: 0,
    height: 0
  },
  footRect: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    x: 0,
    y: 0,
    width: 0,
    height: 0
  }
}

export default handleActions({
  [ACTION_TYPE.SET_HEAD_RECT]: (state, action) => ({ ...state, headRect: action.payload }),
  [ACTION_TYPE.SET_FOOT_RECT]: (state, action) => ({ ...state, footRect: action.payload })
}, initialState)

export const actions = {
  setHeadRect: createAction(ACTION_TYPE.SET_HEAD_RECT),
  setFootRect: createAction(ACTION_TYPE.SET_FOOT_RECT)
}
