import { createAction, handleActions } from 'redux-actions'

const ACTION_TYPE = {
  SET_INITIALIZED: 'app/UI/Window/SET_INITIALIZED',
  SET_SCROLL_TOP: 'app/UI/Window/SET_SCROLL_TOP',
  SET_LAST_SCROLL_TOP: 'app/UI/Window/SET_LAST_SCROLL_TOP',
  SET_SCROLL_DIRECTION: 'app/UI/Window/SET_SCROLL_DIRECTION',
  SET_WIDTH: 'app/UI/Window/SET_WIDTH',
  SET_HEIGHT: 'app/UI/Window/SET_HEIGHT',
  SET_FULL_HEIGHT: 'app/UI/Window/SET_FULL_HEIGHT',
  SET_MEDIA_TYPE: 'app/UI/Window/SET_MEDIA_TYPE',
  SET_LAST_MEDIA_TYPE: 'app/UI/Window/SET_LAST_MEDIA_TYPE',
  SET_UPDATE_STATE: 'app/UI/Window/SET_UPDATE_STATE',
  SET_SCROLLED: 'app/UI/Window/SET_SCROLLED',
  SET_ALL: 'app/UI/Window/SET_ALL'
}

const initialState = {
  initialized: false,
  scrollTop: -1,
  lastScrollTop: -1,
  scrollDirection: 'none',
  width: 0,
  height: 0,
  fullHeight: 0,
  lastMediaType: null,
  mediaType: null,
  updateState: 0,
  scrolled: false
}

export default handleActions({
  [ACTION_TYPE.SET_INITIALIZED]: (state, action) => ({ ...state, initialized: action.payload }),
  [ACTION_TYPE.SET_SCROLL_TOP]: (state, action) => ({ ...state, scrollTop: action.payload }),
  [ACTION_TYPE.SET_LAST_SCROLL_TOP]: (state, action) => ({ ...state, lastScrollTop: action.payload }),
  [ACTION_TYPE.SET_SCROLL_DIRECTION]: (state, action) => ({ ...state, scrollDirection: action.payload }),
  [ACTION_TYPE.SET_WIDTH]: (state, action) => ({ ...state, width: action.payload }),
  [ACTION_TYPE.SET_HEIGHT]: (state, action) => ({ ...state, height: action.payload }),
  [ACTION_TYPE.SET_FULL_HEIGHT]: (state, action) => ({ ...state, fullHeight: action.payload }),
  [ACTION_TYPE.SET_MEDIA_TYPE]: (state, action) => ({ ...state, mediaType: action.payload }),
  [ACTION_TYPE.SET_LAST_MEDIA_TYPE]: (state, action) => ({ ...state, lastMediaType: action.payload }),
  [ACTION_TYPE.SET_UPDATE_STATE]: (state, action) => ({ ...state, updateState: action.payload }),
  [ACTION_TYPE.SET_SCROLLED]: (state, action) => ({ ...state, scrolled: action.payload }),
  [ACTION_TYPE.SET_ALL]: (state, action) => ({ ...state, ...action.payload })
}, initialState)

export const actions = {
  setInitialized: createAction(ACTION_TYPE.SET_INITIALIZED),
  setScrollTop: createAction(ACTION_TYPE.SET_SCROLL_TOP),
  setLastScrollTop: createAction(ACTION_TYPE.SET_LAST_SCROLL_TOP),
  setScrollDirection: createAction(ACTION_TYPE.SET_SCROLL_DIRECTION),
  setWidth: createAction(ACTION_TYPE.SET_WIDTH),
  setHeight: createAction(ACTION_TYPE.SET_HEIGHT),
  setFullHeight: createAction(ACTION_TYPE.SET_FULL_HEIGHT),
  setLastMediaType: createAction(ACTION_TYPE.SET_MEDIA_TYPE),
  setMediaType: createAction(ACTION_TYPE.SET_LAST_MEDIA_TYPE),
  setUpdateState: createAction(ACTION_TYPE.SET_UPDATE_STATE),
  setScrolled: createAction(ACTION_TYPE.SET_SCROLLED),
  setAll: createAction(ACTION_TYPE.SET_ALL)
}
