import React from 'react'
import {
  TransitionGroup,
  Transition
} from 'react-transition-group'
import styled from '@emotion/styled'
import { easing } from '@/utils/preset'

const duration = 400
const enterDelay = 400

const getTransitionStyles = {
  entering: {
    opacity: 0,
    transform: `translateY(10px)`
  },
  entered: {
    transition: `opacity ${duration}ms ${easing.css.easeOutSine} ${enterDelay}ms, transform ${duration}ms ${easing.css.easeOutSine} ${enterDelay}ms`,
    opacity: 1
  },
  exiting: {
    transition: `opacity ${duration}ms ${easing.css.easeInSine}, transform ${duration}ms ${easing.css.easeInSine}`,
    opacity: 0,
    transform: `translateY(-10px)`
  },
  exited: {
    display: 'none'
  }
}

let newPage = null
let oldPage = null

export const setPage = page => {
  oldPage = newPage
  newPage = page
}

class PageTransition extends React.PureComponent {
  onEntered = node => {
    window.scrollTo(0, 0)
    setTimeout(() => {
      newPage.componentDidEnter()
      document.documentElement.style.pointerEvents = ''
    }, duration)
  }
  onExit = node => {
    node.parentNode.appendChild(node.previousSibling)
    oldPage.componentWillExit()
    this.setState({
      status: 1
    })
    document.documentElement.style.pointerEvents = 'none'
  }
  render () {
    const { children, location } = this.props
    return (
      <Root>
        <TransitionGroup>
          <Transition
            key={location.pathname}
            timeout={{
              enter: duration + enterDelay,
              exit: duration
            }}
            onExit={this.onExit}
            onEntered={this.onEntered}
          >
            {status => {
              return (
                <div
                  style={{
                    ...getTransitionStyles[status]
                  }}
                >
                  {children}
                </div>
              )
            }}
          </Transition>
        </TransitionGroup>
      </Root>
    )
  }
}

const Root = styled.div``

export default PageTransition
