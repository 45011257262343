import { createAction, handleActions } from 'redux-actions'

const ACTION_TYPE = {
  SET_LOCATION: 'app/Route/SET_LOCATION'
}

const initialState = {
  location: typeof window !== 'undefined' ? window.location : null
}

export default handleActions({
  [ACTION_TYPE.SET_LOCATION]: (state, action) => ({ ...state, location: action.payload })
}, initialState)

export const actions = {
  setLocation: createAction(ACTION_TYPE.SET_LOCATION)
}
