import colors from './colors'
import media from './media'
import layouts from './layouts'
import fonts from './fonts'
import easing from './easing'
import zIndex from './z-index'

export default {
  colors,
  media,
  layouts,
  fonts,
  easing
}

export {
  colors,
  media,
  layouts,
  fonts,
  easing,
  zIndex
}
