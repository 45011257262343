import React from 'react'
import MuiButton from '@material-ui/core/Button'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import { darken, lighten } from '@material-ui/core/styles/colorManipulator'
import { fonts } from '@/utils/preset'
import { getThemeColor, getThemeContrastColor } from '@/utils/themes'

const Button = props => {
  const { theme, classes, backgroundAlpha, children, className, color, ...other } = props
  return (
    <MuiButton
      className={className}
      variant="contained"
      classes={classes}
      {...other}
    >
      { children }
    </MuiButton>
  )
}

const getStyles = (props) => {
  if (props.type === 'contrast') {
    return {
      backgroundColor: props.theme.background.contrast,
      color: props.theme.text.contrast,
      hoverBackgroundColor: darken(props.theme.background.contrast, 0.1)
    }
  } else if (props.color) {
    return {
      backgroundColor: getThemeColor(props.color, props.theme),
      color: getThemeContrastColor(props.color, props.theme)
    }
  } else if (props.scale) {
    return {
      backgroundColor: props.theme.scale[props.scale],
      color: props.theme.scale.contrast[props.scale],
      hoverBackgroundColor: props.theme.scale.hover[props.scale]
    }
  } else {
    return {
      backgroundColor: props.theme.background.default,
      color: props.theme.text.default,
      hoverBackgroundColor: lighten(props.theme.background.default, 0.1)
    }
  }
}

const styles = props => {
  const styles = getStyles(props)
  return {
    root: {
      backgroundColor: styles.backgroundColor,
      color: styles.color,
      borderRadius: 20,
      height: 40,
      padding: '0 24px',
      fontSize: 14,
      fontFamily: fonts.base,
      boxShadow: 'none',
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: styles.hoverBackgroundColor,
        boxShadow: 'none',
        '@media (hover: none)': {
          backgroundColor: styles.backgroundColor
        },
        '&:disabled': {
          backgroundColor: 'transparent'
        }
      },
      '&:disabled': {
        backgroundColor: styles.backgroundColor,
        color: styles.color,
        opacity: 0.6
      }
    }
  }
}

export default ThemeConsumer(WithStylesProps(styles)(Button))
