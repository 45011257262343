import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from '@emotion/styled'
import { Global } from '@emotion/core'
import { Location } from '@reach/router'
import { actions as layoutActions } from '@/store/UI/Layout'
import ThemeContext from '@/context/Theme'
import { rgba } from '@/utils/helpers'
import Header from '@/components/common/Header'
import Footer from '@/components/common/Footer'
import PageTransition from '@/components/common/PageTransition'
import themes from '@/utils/themes'
import { layouts } from '@/utils/preset'

class DefaultLayout extends React.Component {
  constructor (props) {
    super(props)
    this.toggleTheme = () => {
      this.setState(state => ({
        theme:
          state.theme === themes.base
            ? themes.light
            : themes.base
      }))
    }
    this.state = {
      theme: themes.base,
      toggleTheme: this.toggleTheme
    }
    this.headRef = React.createRef()
  }
  componentDidMount () {
    this.updateLayoutStates()
  }
  componentDidUpdate (prevProps) {
    if (!this.props.windowInitialized) {
      return
    }
    if (prevProps.windowUpdateState !== 1 && this.props.windowUpdateState === 1) {
      this.updateLayoutStates()
    }
  }
  updateLayoutStates () {
    this.props.setHeadRect(this.headRef.current.getBoundingClientRect())
  }
  render () {
    return (
      <Location>
        {({ location }) => (
          <ThemeContext.Provider value={this.state}>
            <Global styles={{
              body: {
                backgroundColor: this.state.theme.background.default,
                color: this.state.theme.text.default
              }
            }}/>
            <Root>
              <Head ref={this.headRef}>
                <Header />
              </Head>
              <PageTransition location={location}>
                <Main>
                  {this.props.children}
                </Main>
                <Foot>
                  <Footer />
                </Foot>
              </PageTransition>
              <Lines_Top windowHeight={this.props.windowHeight}>
                <Lines_Container theme={this.state.theme}>
                  <Line theme={this.state.theme} />
                  <Line theme={this.state.theme} />
                  <Line theme={this.state.theme} />
                </Lines_Container>
              </Lines_Top>
            </Root>
          </ThemeContext.Provider>
        )}
      </Location>
    )
  }
}

const mapStateToProps = state => {
  return {
    windowHeight: state.UI.Window.fullHeight,
    windowInitialized: state.UI.Window.initialized,
    windowUpdateState: state.UI.Window.updateState,
    ...state.UI.Layout
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  ...layoutActions
}, dispatch)

export default connect(
  mapStateToProps, mapDispatchToProps
)(DefaultLayout)

const Root = styled.div`
  overflow: hidden;
`

const Lines = styled.div`
  pointer-events: none;
  transform: translate3d(0, 0, 0);
`

const Lines_Top = styled(Lines)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${props => props.windowHeight}px;
`

const Lines_Container = styled(layouts.components.Container())`
  display: flex;
  justify-content: space-between;
  height: 100%;
`

const Line = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${props => rgba(props.theme.scale[400], 0.2)};
`

const Head = styled.div`
`

const Main = styled(layouts.components.Container())`
  position: relative;
  z-index: 1;
  height: 100%;
`

const Foot = styled.div`
  position: relative;
`
