import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as windowActions } from '@/store/UI/Window'
import presets from '@/utils/preset'

const mapStateToProps = state => {
  return {
    ...state.UI.Window
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  ...windowActions
}, dispatch)

class WindowManager extends React.Component {
  componentDidMount () {
    if (typeof window === 'undefined') return
    this.onScrollWindow = this.onScrollWindow.bind(this)
    this.onResizeWindow = this.onResizeWindow.bind(this)
    window.addEventListener('scroll', this.onScrollWindow)
    window.addEventListener('resize', this.onResizeWindow)
    this.update()
  }
  getWindowWidth () {
    if (typeof window === 'undefined') return 0
    return window.innerWidth
  }
  getWindowHeight () {
    if (typeof window === 'undefined') return 0
    return window.innerHeight
  }
  getWindowFullHeight () {
    if (typeof window === 'undefined') return 0
    var axis = Math.abs(window.orientation)
    var dims = { w: 0, h: 0 }
    var ruler = document.createElement('div')
    ruler.style.position = 'fixed'
    ruler.style.height = '100vh'
    ruler.style.width = 0
    ruler.style.top = 0
    document.documentElement.appendChild(ruler)
    dims.w = axis === 90 ? ruler.offsetHeight : window.innerWidth
    dims.h = axis === 90 ? window.innerWidth : ruler.offsetHeight
    document.documentElement.removeChild(ruler)
    ruler = null
    if (Math.abs(window.orientation) !== 90) {
      return dims.h
    }
    return dims.w
  }
  getScrollTop () {
    if (!window) {
      return 0
    }
    const w = window
    return window.self === window
      ? w.pageYOffset
      : w.scrollTop
  }
  scrollTo (x, y) {
    window.scrollTo(x, y)
  }
  onScrollWindow () {
    const scrollTop = this.getScrollTop()
    const lastScrollTop = this.props.scrollTop
    let scrollDirection
    if (lastScrollTop > scrollTop) {
      scrollDirection = 'up'
    } else if (lastScrollTop < scrollTop) {
      scrollDirection = 'down'
    } else {
      scrollDirection = 'none'
    }
    const scrolled = scrollTop > 0
    const nextState = {
      scrollTop, lastScrollTop, scrollDirection
    }
    if (scrolled !== this.props.scrolled) {
      nextState.scrolled = scrolled
    }
    this.props.setAll(nextState)
  }
  onResizeWindow () {
    this.update()
  }
  update () {
    const firstState = {
      updateState: 0,
      width: this.getWindowWidth(),
      height: this.getWindowHeight(),
      fullHeight: this.getWindowFullHeight()
    }
    const mediaType = this.getCurrentMediaType()
    if (this.props.mediaType !== mediaType) {
      firstState.mediaType = mediaType
      firstState.lastMediaType = this.props.mediaType
    }
    this.props.setAll(firstState)
    const scrollTop = this.getScrollTop()
    const scrolled = scrollTop > 0
    const secondState = { scrollTop }
    if (scrolled !== this.props.scrolled) {
      secondState.scrolled = scrolled
    }
    this.props.setAll(secondState)
    this.props.setUpdateState(1)
    this.props.setInitialized(true)
  }
  getCurrentMediaType () {
    let mediaType
    if (this.getWindowWidth() >= presets.media.desktop.min) {
      mediaType = presets.media.desktop.key
    } else if (this.getWindowWidth() >= presets.media.laptop.min) {
      mediaType = presets.media.laptop.key
    } else if (this.getWindowWidth() >= presets.media.tablet.min) {
      mediaType = presets.media.tablet.key
    } else {
      mediaType = presets.media.mobile.key
    }
    return mediaType
  }
  render () {
    return (
      <Fragment>
        {this.props.children}
      </Fragment>
    )
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps
)(WindowManager)
