import React from 'react'
import Helmet from 'react-helmet'

function InlineScripts () {
  return (
    <Helmet>
      <script type="application/javascript">{`
        (function () {
          if (window.initialSize) {
            return;
          }
          window.initialSize = {
            width: window.innerWidth,
            height: (function () {
              var axis = Math.abs(window.orientation);
              var dims = { w: 0, h: 0 };
              var ruler = document.createElement('div');
              ruler.style.position = 'fixed';
              ruler.style.height = '100vh';
              ruler.style.width = 0;
              ruler.style.top = 0;
              document.documentElement.appendChild(ruler);
              dims.w = axis === 90 ? ruler.offsetHeight : window.innerWidth;
              dims.h = axis === 90 ? window.innerWidth : ruler.offsetHeight;
              document.documentElement.removeChild(ruler);
              ruler = null;
              if (Math.abs(window.orientation) !== 90) {
                return dims.h;
              }
              return dims.w;
            })()
          };
        })();
      `}</script>
    </Helmet>
  )
}

export default InlineScripts
