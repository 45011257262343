import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { withStyles } from '@material-ui/core/styles'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { tablet, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import { layouts, zIndex } from '@/utils/preset'
import Drawer from '@material-ui/core/Drawer'
import Button from '@/components/common/Button'
import List from '@material-ui/core/List'
import ListItem from '@/components/common/ListItem'
import ListItemText from '@/components/common/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import Icon from '@material-ui/icons/ExpandMore'

class NavMenu extends React.Component {
  constructor (props) {
    super(props)
    const state = {
      open: false,
      nestedMenus: {}
    }
    props.routes.main.filter(route => {
      return route.childLists
    }).forEach(route => {
      state.nestedMenus[route.path] = false
    })
    this.state = state
  }
  toggleDrawer = () => {
    this.setState({
      open: !this.state.open
    })
  }
  closeDrawer = () => {
    this.setState({
      open: false
    })
  }
  handleClick = (path) => () => {
    const state = {
      ...this.state
    }
    state.nestedMenus[path] = !this.state.nestedMenus[path]
    this.setState(state)
  }
  renderListItems (routes) {
    const { classes } = this.props
    return (
      <Fragment>
        {routes.map((item, index) => {
          return (
            <Fragment key={index}>
              {!item.childLists && (
                <ListItem
                  button
                  component={Link}
                  activeClassName="active"
                  to={item.path}
                  onClick={this.closeDrawer}>
                  <ListItemText primary={item.title} />
                </ListItem>
              )}
              {item.childLists && (
                <Fragment>
                  <ListItem
                    button
                    onClick={this.handleClick(item.path)}>
                    <ListItemText
                      primary={item.title}/>
                    <ExpandMore open={this.state.nestedMenus[item.path]}/>
                  </ListItem>
                  <Collapse in={this.state.nestedMenus[item.path]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.path && (
                        <ListItem
                          button
                          component={Link}
                          activeClassName="active"
                          to={item.path}
                          className={classes.nested}
                          onClick={this.closeDrawer}>
                          <ListItemText primary={
                            item.indexTitle
                              ? item.indexTitle
                              : `${item.title}トップ`
                          } />
                        </ListItem>
                      )}
                      {item.childLists.map((child, index) => (
                        <ListItem
                          key={index}
                          button
                          className={classes.nested}
                          to={child.path}
                          component={Link}
                          activeClassName="active"
                          onClick={this.closeDrawer}>
                          <ListItemText primary={child.title} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </Fragment>
              )}
            </Fragment>
          )
        })}
      </Fragment>
    )
  }
  render () {
    const { theme, classes, routes, ...other } = this.props
    return (
      <Root {...other}>
        <MenuButton type="contrast" onClick={this.toggleDrawer} open={this.state.open}>
          <MenuButton_Container>
            <MenuButton_Open theme={theme}>
              <div></div>
              <div></div>
              <div></div>
            </MenuButton_Open>
          </MenuButton_Container>
        </MenuButton>
        <Drawer anchor="right" open={this.state.open} onClose={this.closeDrawer}>
          <List
            component="nav"
            className={classes.root}
          >
            <ListItem
              button
              component={Link}
              activeClassName="active"
              to="/"
              onClick={this.closeDrawer}>
              <ListItemText primary="サイトトップ" />
            </ListItem>
            {this.renderListItems(routes.main)}
            {this.renderListItems(routes.conversion)}
            {this.renderListItems(routes.others)}
          </List>
        </Drawer>
      </Root>
    )
  }
}

const Root = styled.div``

const ExpandMore = styled(Icon)`
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: ${props => props.open
    ? 'rotate(180deg)'
    : 'rotate(0deg)'
  };
`

const MenuButton = styled(Button)`
  z-index: ${zIndex.nav.base};
  position: fixed;
  top: 12px;
  @media ${mq.and(tablet)} {
    right: ${layouts.defs.pad.tablet + 1}px;
  }
  @media ${mq.and(mobile)} {
    right: ${layouts.defs.pad.mobile + 1}px;;
  }
  @media (max-width: ${mobile.max}px) and (min-width: ${layouts.defs.mobileMaxWidth + layouts.defs.pad.mobile * 2}px) {
    right: calc((100vw - ${layouts.defs.mobileMaxWidth}px) / 2 + 1px);
  }
  width: 40px !important;
  height: 40px !important;
  border-radius: 0 !important;
  padding: 0 !important;
  min-width: auto !important;
`

const MenuButton_Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 12px;

  > * {
    width: 100%;
    height: 100%;
  }
`

const MenuButton_Open = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  > div {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: ${props => `2px solid ${props.theme.text.contrast}`};

    &:nth-of-type(1) {
      top: 0;
    }

    &:nth-of-type(2) {
      top: 50%;
    }

    &:nth-of-type(3) {
      top: 100%;
    }
  }
`

const muiStyles = theme => ({
  root: {
    width: 240
  },
  nested: {
    paddingLeft: 24
  }
})

export default ThemeConsumer(withStyles(muiStyles)(NavMenu))
