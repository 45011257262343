import React from 'react'
import ThemeContext from '@/context/Theme'

const ThemeConsumer = Component => function ThemeConsumer (props) {
  return (
    <ThemeContext.Consumer>
      {({ theme }) => <Component theme={theme} {...props} />}
    </ThemeContext.Consumer>
  )
}

export default ThemeConsumer
