import React from 'react'
import styled from '@emotion/styled'
import MuiListItem from '@material-ui/core/ListItem'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import MuiWrapper from '@/hoc/MuiWrapper'

const ListItem = props => {
  const { theme, classes, children, className, ...other } = props
  return (
    <Root
      className={className}
      theme={theme}
      classes={{
        ...get(classes)
      }}
      {...other}
    >
      { children }
    </Root>
  )
}

const getColor = (props) => {
  if (props.type === 'contrast') {
    return props.theme.text.contrast
  } else {
    return props.theme.text
  }
}

export const make = props => {
  const color = getColor(props)
  return {
    ListItem_root: {
      color: color
    }
  }
}

const Root = styled(MuiListItem)`
  &.active {
    font-weight: bold;
    color: ${props => props.theme.primary.base};
  }
`

export const get = classes => {
  const filteredClasses = {}
  Object.keys(classes).forEach(className => {
    if (className.indexOf('ListItem_') === 0) {
      filteredClasses[className.replace('ListItem_', '')] = classes[className]
    }
  })
  return filteredClasses
}

export default MuiWrapper(ThemeConsumer(
  WithStylesProps(make)(ListItem)
), 'ListItem')
