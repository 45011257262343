import React from 'react'
import styled from '@emotion/styled'
import { aspectRatio, aspectRatioChild } from '@/utils/helpers'

const Logo = props => (
  <Root {...props}>
    <SVG width="505" height="119" viewBox="0 0 505 119" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M45.4 118.1L43.6 117.3C17.1 105.2 0 78.6 0 49.6V20.1L4.3 20C15.7 19.9 36 6.9 42.8 1.9L45.4 0L48 1.9C54.9 6.9 75.2 19.9 86.5 20.1L90.8 20.2V49.6C90.8 78.7 73.7 105.2 47.2 117.3L45.4 118.1ZM8.7 28.4V49.6C8.7 74.6 23 97.5 45.4 108.5C67.7 97.5 82.1 74.6 82.1 49.6V28.4C68.8 26.1 51.8 15.2 45.4 10.7C39 15.1 22 26.1 8.7 28.4Z" fill="#000"/>
      <path d="M58.0001 46.8L51.8001 50.4V42.9C51.8001 41.4 52.6002 40 53.9002 39.2C55.9002 38 58.5001 38.7 59.6001 40.7L68.0001 35.8C64.2001 29.2 55.6001 26.9 49.0001 30.7C44.7001 33.2 42.0001 37.8 42.0001 42.8V55.8L30.8001 62.2C24.2001 66 21.9002 74.6 25.7002 81.2C29.5002 87.8 38.1001 90.1 44.7001 86.3C48.9001 83.8 51.6001 79.3 51.7001 74.4V61.4L62.8001 55L58.0001 46.8ZM42.1001 74.5C42.1001 76 41.3001 77.4 40.0001 78.1C38.0001 79.3 35.4001 78.6 34.3001 76.6C33.1001 74.6 33.8001 72 35.8001 70.9L42.1001 67.3V74.5Z" fill="#000"/>
      <path d="M221.5 55C232.584 55 241 64.0957 241 74C241 83.9043 232.687 93 221.5 93C210.518 93 202 83.9043 202 74C202 64.0957 210.416 55 221.5 55ZM231.661 74C231.661 67.8351 227.247 63.3883 221.5 63.3883C215.855 63.3883 211.339 67.734 211.339 74C211.339 80.1649 215.855 84.6117 221.5 84.6117C227.247 84.5106 231.661 80.1649 231.661 74Z" fill="#000"/>
      <path d="M395.93 67.16C394.284 63.4107 392.226 62.1947 390.169 62.1947C388.214 62.1947 386.465 63.1067 386.465 65.336C386.465 72.1253 402 66.9573 402 80.4347C402 87.6293 395.827 93 388.214 93C384.099 93 380.395 91.6827 377 88.136L382.453 81.9547C383.893 83.88 386.259 85.704 388.934 85.704C391.198 85.704 393.152 84.3867 393.152 81.752C393.152 75.064 377.617 78.6107 377.617 66.9573C377.617 60.3707 382.761 55 389.963 55C394.798 55 399.222 58.344 401.588 62.1947L395.93 67.16Z" fill="#000"/>
      <path d="M446.5 55C457.584 55 466 64.0957 466 74C466 83.9043 457.687 93 446.5 93C435.518 93 427 83.9043 427 74C427.103 64.0957 435.518 55 446.5 55ZM456.661 74C456.661 67.8351 452.247 63.3883 446.5 63.3883C440.753 63.3883 436.339 67.734 436.339 74C436.339 80.1649 440.855 84.6117 446.5 84.6117C452.35 84.5106 456.661 80.1649 456.661 74Z" fill="#000"/>
      <path d="M359.93 57.8437V93.493H369.203H369.306V57.8437H359.93Z" fill="#000"/>
      <path d="M369.306 39.5038H359.93V49.1889H369.306V39.5038Z" fill="#000"/>
      <path d="M419.38 93.493V57.8437H410.004V93.493H419.277H419.38Z" fill="#000"/>
      <path d="M419.38 39.5038H410.004V49.1889H419.38V39.5038Z" fill="#000"/>
      <path d="M290.606 46V56.705H287V65.167H290.606V81.0716C290.606 89.6356 296.864 93 305.985 93H308V84.538H306.621C302.697 84.538 300.152 82.7028 300.152 77.8091V65.167H308V56.705H300.152V46H290.606Z" fill="#000"/>
      <path d="M147.194 39.2891C147.194 37.8568 147.917 36.5269 149.26 35.8107C151.223 34.6853 153.702 35.4015 154.839 37.243L163 32.6392C159.281 26.2962 151.12 24.1478 144.715 27.7285C140.583 30.0816 138 34.583 138 39.2891V41.5399V93H147.298V72.3341V65.6842H155.562V57.1928H147.298L147.194 39.2891Z" fill="#000"/>
      <path d="M175.66 57.7967C171.561 60.1504 169 64.6532 169 69.3606V93H178.324V69.3606C178.324 67.9279 179.041 66.5975 180.373 65.8812C182.32 64.7555 184.779 65.4719 185.906 67.3139L194 62.7088C190.107 56.2617 182.012 54.1126 175.66 57.7967Z" fill="#000"/>
      <path d="M257.403 71.3315C257.403 67.9586 260.09 65.3011 263.5 65.3011C266.807 65.3011 269.597 67.9586 269.597 71.3315V93H279V71.3315C279 62.8481 272.077 56 263.5 56C255.027 56 248 62.8481 248 71.3315V93H257.403V71.3315Z" fill="#000"/>
      <path d="M483.403 71.3315C483.403 67.9586 486.09 65.3011 489.5 65.3011C492.91 65.3011 495.597 67.9586 495.597 71.3315V93H505V71.3315C505 62.8481 498.077 56 489.5 56C481.027 56 474 62.8481 474 71.3315V93H483.403V71.3315Z" fill="#000"/>
      <path d="M333.949 71.9306L326.242 56H316L328.879 82.4139L333.949 93L339.121 82.4139L352 56H341.758L333.949 71.9306Z" fill="#000"/>
    </SVG>
  </Root>
)

const Root = styled.div`
  ${aspectRatio(505, 119)};
`

const SVG = styled.svg`
  ${aspectRatioChild};
`

export default Logo
