import { createStore, combineReducers } from 'redux'
import * as UI from './UI'
import Route from './Route'

export default createStore(combineReducers({
  UI: combineReducers({
    ...UI
  }),
  Route
}))
