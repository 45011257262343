import React from 'react'
import MuiListItemText from '@material-ui/core/ListItemText'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import MuiWrapper from '@/hoc/MuiWrapper'
import { fonts } from '@/utils/preset'

const ListItemText = props => {
  const { theme, classes, children, className, ...other } = props
  return (
    <MuiListItemText
      className={className}
      classes={{
        ...get(classes)
      }}
      {...other}
    >
      { children }
    </MuiListItemText>
  )
}

export const make = props => {
  return {
    ListItemText_root: {
      '& > *': {
        fontSize: '12px !important',
        fontFamily: `${fonts.base} !important`,
        fontWeight: 'inherit',
        color: 'inherit'
      }
    }
  }
}

export const get = classes => {
  const filteredClasses = {}
  Object.keys(classes).forEach(className => {
    if (className.indexOf('ListItemText_') === 0) {
      filteredClasses[className.replace('ListItemText_', '')] = classes[className]
    }
  })
  return filteredClasses
}

export default MuiWrapper(ThemeConsumer(
  WithStylesProps(make)(ListItemText)
), 'ListItemText')
