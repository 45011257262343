import React from 'react'
import { ClassNames } from '@emotion/core'

const WithStylesProps = (stylesProps) => Component => function WithStylesProps (props) {
  return (
    <ClassNames>
      {({ css }) => {
        const styles = stylesProps(props)
        const classes = {}
        Object.keys(styles).forEach(key => {
          const style = styles[key]
          classes[key] = css(style)
        })
        return <Component classes={classes} {...props} />
      }}
    </ClassNames>
  )
}

export default WithStylesProps
