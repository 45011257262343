import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Icon = ({ name, className }) => (
  <Root className={`icons icons-${name} ${className}`}></Root>
)

const Root = styled.i`
  visibility: hidden;
  html.wf-active &,
  html.wf-inactive & {
    visibility: visible;
  }
`

Icon.defaultProps = {
  name: ''
}

Icon.propTypes = {
  name: PropTypes.string
}

export default Icon
