import { darken, lighten } from '@material-ui/core/styles/colorManipulator'

export default {
  base: {
    primary: {
      light: '#6087FF',
      base: '#000',
      dark: '#000',
      contrast: {
        light: '#fff',
        base: '#fff',
        dark: '#fff'
      }
    },
    secondary: {
      light: '#6EFFE8',
      base: '#FDD33F',
      dark: '#00B686',
      contrast: {
        light: '#fff',
        base: '#000',
        dark: '#fff'
      }
    },
    scale: {
      900: '#FFF',
      800: '#EFEFEF',
      700: '#DADADA',
      600: '#C7C7C7',
      500: '#B5B5B5',
      400: '#A2A2A2',
      300: '#909090',
      200: '#7D7D7D',
      100: '#6B6B6B',
      50: '#585858',
      contrast: {
        900: '#000',
        800: '#000',
        700: '#000',
        600: '#000',
        500: '#000',
        400: '#000',
        300: '#FFF',
        200: '#FFF',
        100: '#FFF',
        50: '#FFF'
      },
      hover: {
        900: darken('#FFF', 0.1),
        800: darken('#EFEFEF', 0.1),
        700: lighten('#DADADA', 0.1),
        600: lighten('#C7C7C7', 0.1),
        500: lighten('#B5B5B5', 0.1),
        400: lighten('#A2A2A2', 0.1),
        300: lighten('#909090', 0.1),
        200: lighten('#7D7D7D', 0.1),
        100: lighten('#6B6B6B', 0.1),
        50: lighten('#585858', 0.1)
      }
    },
    text: {
      default: '#000',
      contrast: '#fff'
    },
    background: {
      default: '#fff',
      contrast: '#000'
    },
    error: '#F54F27',
    link: '#6087FF'
  }
}

export const parseThemeColor = keyPath => {
  const array = keyPath.split('.')
  const type = array[0]
  const tone = array.length > 1 ? array[1] : 'base'
  return { type, tone }
}

export const getThemeColor = (keyPath, theme) => {
  const { type, tone } = parseThemeColor(keyPath)
  return theme[type][tone]
}

export const getThemeContrastColor = (keyPath, theme) => {
  const { type, tone } = parseThemeColor(keyPath)
  return theme[type].contrast[tone]
}
